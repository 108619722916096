<template>
  <div class="container2" dir="ltr" style="padding-top:  2px !important;padding-bottom: 5px !important;"> 
            <div class="slider2">
                <template v-for="post in posts.slice(0,5)" :key="post.id">
                <div class="box1 box"  @click="getContent(post)" v-bind:style="{ 'background-image': 'url(' + post.carsoul_img + ')', 'background-size': 'cover', 'background-position': 'top' }">
                    <div class="bg"></div>
                    <div class="details">
                        <h1>{{ post.title_ar }}</h1>
                        <p>
                            {{ post.desc_ar }}
                        </p>
                        
                    </div>

                    <div class="illustration" @click="getContent(post)"><div class="inner"></div><button class="button" >مشاهدة</button></div>
                </div>
            </template>
                                
                
                
                                
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" class="prev" width="56.898" height="91" viewBox="0 0 56.898 91"><path d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z" transform="translate(0 91) rotate(-90)" fill="#fff"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="next" width="56.898" height="91" viewBox="0 0 56.898 91"><path d="M45.5,0,91,56.9,48.452,24.068,0,56.9Z" transform="translate(56.898) rotate(90)" fill="#fff"/></svg>
            <div class="trail2" id="trail2">
                    <div class="box1 active">1</div>
                    <div class="box2">2</div>
                    <div class="box3">3</div>
                    <div class="box4">4</div>
                    <div class="box5">5</div>
            </div>
        </div>   




    

    
    
    <div class="catalog">
        <div class="container">
            
            <div class="catalog__nav">
        <div class="row row-grid" style="justify-content: center;margin-bottom: 5vh;">
            <template v-for="publish in Categories" :key="publish.id">
							<div class="col-6 col-sm-6 col-lg-3 col-xl-2" style="margin-top: 10px">
								<div class="ms_rcnt_box marger_bottom30 pointer" style="margin-bottom: 10px;">
									<div @click="goToCategory(publish)" class="ms_rcnt_box_img margin-zero card_category">
										<img style="width:100%" :src="publish.tab_img" alt="" class="img-fluid">
										<div @click="goToCategory(publish)" class="ms_main_overlay">
											<div class="ms_box_overlay"></div>
										</div>
									</div>
									<div class="  " style="z-index:999;display:block;position:relative;">
										<h3><a @click="goToCategory(publish)" class="text_size" style="color: rgb(255, 255, 255); font-weight: 600;font-size: 15px;"></a></h3>
									</div>
								</div>
							</div>
						</template>
                    </div>	
                        </div>
                
          
            <div class="row">
                <div class="col-12">    
                    <div class="catalog__nav" dir="rtl">
                        <div class="slider-radio tabs">
                            <input type="radio" name="grade" id="featured" data-tab-value="#tab_1" checked="checked"><label for="featured"> مقترحة</label>
                            <input type="radio" name="grade" id="popular" data-tab-value="#tab_2"><label for="popular"> شائعة</label>
                            <input type="radio" name="grade" id="newest" data-tab-value="#tab_3"><label for="newest"> جديدة</label>
                        </div>
                    </div>
                    <div class="tab-content">
                       <div class="tabs__tab active" id="tab_1" data-tab-info>
                            <div class="row row--grid" style="margin-top: 5px;border-radius: 12px;padding: 5px;margin-right: 3px;margin-left: 3px;margin-bottom: 5vh;">
                                <div class="col-12 col-sm-4 col-lg-3 col-xl-4"  v-for="post in posts" :key="post.id">
                                    <div class="card">
                                        <a @click="getContent(post)" class="card__cover">
                                           <img v-lazy="post.img" alt="">
                                           <svgVideo />
                                        </a>
                                        <h3 class="card__title"><a @click="getContent(post)">{{ post.title_ar }}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tabs__tab" id="tab_2" data-tab-info>
                            <div class="row row--grid" style="margin-top: 5px;border-radius: 12px;padding: 5px;margin-right: 3px;margin-left: 3px;margin-bottom: 5vh;">
                                <div class="col-12 col-sm-4 col-lg-3 col-xl-4"  v-for="post in popular" :key="post.id">
                                    <div class="card">
                                        <a @click="getContent(post)" class="card__cover">
                                           <img v-lazy="post.img" alt="">
                                           <svgVideo />
                                        </a>
                                        <h3 class="card__title"><a @click="getContent(post)">{{ post.title_ar }}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tabs__tab" id="tab_3" data-tab-info>
                            <div class="row row--grid" style="margin-top: 5px;border-radius: 12px;padding: 5px;margin-right: 3px;margin-left: 3px;margin-bottom: 5vh;">
                                <div class="col-12 col-sm-4 col-lg-3 col-xl-4"  v-for="post in Lastest" :key="post.id">
                                    <div class="card">
                                        <a @click="getContent(post)" class="card__cover">
                                           <img v-lazy="post.img" alt="">
                                           <svgVideo />
                                        </a>
                                        <h3 class="card__title"><a @click="getContent(post)">{{ post.title_ar }}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import svgVideo from "@/components/svgData/svgVideo.vue";
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import { watchEffect } from '@vue/runtime-core';
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
export default {
    components: { 
        svgVideo
    },
    async setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const posts = ref([]);
        const popular = ref([]);
        const Categories = ref([]);
        const Lastest = ref([]);
        
        watchEffect(() => {
            const toast = useToast();
            if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
            let urlParams = new URLSearchParams(window.location.search);
            // console.log(urlParams.get('msisdn'))
            if(urlParams.has('msisdn')) {
                HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                        cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                        toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    }
                    if(cookie.getCookie("content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
        }
        });
        
        try {
            await HTTP.get(`GetAllCategories.php`).then((res) => {
                Categories.value = res.data.Categories; 
            });
        } catch (err) {
            console.log(err);
        }
        try {
            await HTTP.get(`GetAllContent.php?LIMIT=15&OFFSET=0`).then((res) => {
                posts.value = res.data.Content; 
            });
        } catch (err) {
            console.log(err);
        }
        try {
            await HTTP.get(`getViewedContent.php?LIMIT=15&OFFSET=0`).then((res) => {
                popular.value = res.data.Content; 
            });
        } catch (err) {
            console.log(err);
        }
        try {
            await HTTP.get(`getLastestContent.php?LIMIT=15&OFFSET=0`).then((res) => {
                Lastest.value = res.data.Content; 
            });
        } catch (err) {
            console.log(err);
        }
        const getContent = (post) => {
            cookie.setCookie('cat_id', post.cat_id);
            if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
                router.push({ name: "Content", params: { content_id: post.id } });
            } else {
                cookie.setCookie('content_id', post.id);
                window.open("https://dsplp.sd.zain.com/?p=9619984619", "_blank");	
            }
        };

        const goToCategory = (post) => {
            cookie.setCookie('cat_id', post.id);
            
                router.push({ name: "Category", params: { id: post.id } });

            
        };

      return { posts, popular, Lastest,Categories,goToCategory, getContent };
    },
    
    mounted() {
        // const toast = useToast();
        // let urlParams = new URLSearchParams(window.location.search);
        //     if(urlParams.has('MSISDN')) {
        //         HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('MSISDN')).then((response) => {
        //     if (response.data.status == 1 ) {
        //         this.$cookie.setCookie("msisdn",response.data.msisdn);
        //         this.$cookie.setCookie("status",response.data.status);
        //         this.$cookie.setCookie("remming_minutes",response.data.remming_minutes);
        //         toast.success("مرحبا بك معنا  ", { timeout: 1000 });
        //     if(this.$cookie.getCookie("content_id")!=null){
        //         toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
        //         setTimeout(() =>  this.$router.push({name: "Content", params: { content_id: this.$cookie.getCookie("content_id") } }), 2000);
        //     } else {
        //         return;
        //     }
                
        //     }
        //   });
        // }

        let externalScript2 = document.createElement('script')
        externalScript2.setAttribute('src', '/ComedyZain/assets/js/slider_nav.js')
        document.head.appendChild(externalScript2)

        const tabs = document.querySelectorAll('[data-tab-value]')
        const tabInfos = document.querySelectorAll('[data-tab-info]')

        tabs.forEach(tab => {
        tab.addEventListener('click', () => {
            const target = document
                .querySelector(tab.dataset.tabValue);
            tabInfos.forEach(tabInfo => {
                tabInfo.classList.remove('active')
            })
               target.classList.add('active');
            })
        })
    },
}
</script>

<style >
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-social/5.1.1/bootstrap-social.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css'); */
    [data-tab-info] {
        display: none;
    }


.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
text-align: center;
color: #fff;
font-size: 21px;
font-weight: 600;
background: #b0036370;
width: 100%;
height: auto;
border-bottom: 3px dotted #fff;
}
.vueperslides__parallax-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}
 .vueperslides__arrow {
  position: absolute;
  font-size: inherit;
  color: inherit;
  text-align: center;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  z-index: 2;
  line-height: 1;
  background: #D00A77A3;
  border-radius: 50px;
}



</style>



